<div class="content mt-4">
  <div class="container-fluid">


    <div class="row">

      <div class="col-md-8">

        <div class="page-categories">
          <!--<h5 class="title text-center">Menu</h5>-->
          <ul class="nav nav-pills nav-pills-warning nav-pills-icons justify-content-center" role="tablist">
            <li class="nav-item mb-1">
              <a class="nav-link active" data-toggle="tab" href="#myProfile" role="tablist">
                <i class="material-icons">info</i> My Profile
              </a>
            </li>



            <li class="nav-item mb-1" *ngIf="isIncluded('documents_uploads')">
              <a class="nav-link" data-toggle="tab" href="#documents" role="tablist" (click)="documentTab = true">
                <i class="material-icons">assignment</i> Documents
              </a>
            </li>
            <li class="nav-item mb-1"  *ngIf="isIncluded('directors_uploads')">
              <a class="nav-link" data-toggle="tab" href="#directors" role="tablist" (click)="directorTab = true">
                <i class="material-icons">group</i> Directors
              </a>
            </li>
            <li class="nav-item mb-1" *ngIf="isIncluded('shareholders_uploads')">
              <a class="nav-link" data-toggle="tab" href="#shareHolders" role="tablist"  (click)="shareholderTab = true">
                <i class="material-icons">group_add</i> Shareholders
              </a>
            </li>
            <li class="nav-item mb-1" *ngIf="isIncluded('authorizers_uploads') || isIncluded('authorizers_upload')">
              <a class="nav-link" data-toggle="tab" href="#authorizer" role="tablist" (click)="authorizerTab = true">
                <i class="material-icons">verified_user</i> Authorizers</a>
            </li>
          </ul>
          <div class="tab-content tab-space tab-subcategories">
            <div class="tab-pane active" id="myProfile">
              <div class="container">
                <app-personal-data></app-personal-data>
              <app-user-change-password></app-user-change-password>
              </div>
            </div>
            <div class="tab-pane" id="documents">
              <div class="container"><app-manage-documents *ngIf="documentTab"></app-manage-documents></div>
            </div>
            <div class="tab-pane" id="directors">
              <div class="container"><app-manage-directors *ngIf="directorTab"></app-manage-directors></div>
            </div>
            <div class="tab-pane" id="shareHolders">
              <div class="container"><app-manage-shareholders *ngIf="shareholderTab"></app-manage-shareholders></div>
            </div>
            <div class="tab-pane" id="authorizer">
              <div class="container"><app-manage-authorizers *ngIf="authorizerTab"></app-manage-authorizers></div>
            </div>
          </div>
        </div>


      </div>
      <div class="col-md-4">
        <div class="card card-profile">
          <div class="card-avatar">
            <a href="javascript:void(0)">
              <img class="img" [src]="'assets/images/user-img.jpg'" onerror="this.src='assets/images/user-img.jpg'">
            </a>
          </div>
          <!--<input type="file" (change)="uploadPicture($event)" accept="image/*" hidden id="uploadFile" />-->
          <!--<div><a href="javascript:void(0)" (click)="browseImage()">Browse Image</a></div>-->
          <div class="card-body">
            <h6 class="card-category text-gray">{{currentUser?.email}}</h6>
            <h4 class="card-title">{{currentUser?.first_name}}</h4>
            <h6 class="card-title">Status: {{currentUser?.status}}</h6>
            <!--<p class="card-description">
             Client Type: {{currentUser?.login?.client_type?.name}}
            </p>-->
          </div>
        </div>
      </div>
    </div>



  </div>
</div>
