import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/authService/auth.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import {IResponse} from '../../../interfaces/iresponse';
import {UserService} from '../../../services/api-handlers/userService/user.service';
import {EventsService} from '../../../services/eventServices/event.service';
declare const $: any;
@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {
  public passwordCredential = {
    old_password: null,
    password: null,
    password_confirmation: null,
    userId: null
  };
  public loading = false;
  public currentUser;
  constructor(private authService: AuthService, private bootstrapNotifyService: BootstrapNotifyService,
              private userService: UserService, private eventService: EventsService) { }

  ngOnInit() {
    // this.eventService.broadcast('BREADCRUMB', 'Change Password');
    this.currentUser = this.authService.getUserDetails();
    this.passwordCredential.userId = this.currentUser.client.id;
  }
  public updatePassword() {
    console.log('Pass ', this.passwordCredential);
    if (!this.passwordCredential.old_password) {
      this.bootstrapNotifyService.error('Enter previous password!');
    } else if (!this.passwordCredential.password) {
      this.bootstrapNotifyService.error('Enter new password!');
    } else if (!this.passwordCredential.password_confirmation) {
      this.bootstrapNotifyService.error('Confirm new password!');
    } else if (this.passwordCredential.password_confirmation !== this.passwordCredential.password) {
      this.bootstrapNotifyService.error('New password must match confirm password!');
    } else if (this.passwordCredential.password.length < 7) {
      this.bootstrapNotifyService.error('New password length too short!');
    } else {
      this.loading = true;
      this.userService.changePassword(this.passwordCredential).subscribe((res: IResponse) => {
        console.log('Password Update ', res);
        this.loading = false;
        this.resetForm();
        this.bootstrapNotifyService.success('Password change successful');
        this.bootstrapNotifyService.info('Redirecting to login page!');
        this.authService.logOut();
      }, error => {
        console.log('Error ', error);
        this.loading = false;
        this.bootstrapNotifyService.error(error.error.message || 'Unable to update password');
      });
    }
  }
  private resetForm() {
    this.passwordCredential = {
      old_password: null,
      password: null,
      password_confirmation: null,
      userId: this.currentUser.id
    };
  }
}
