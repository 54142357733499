import {Component, Input, OnInit} from '@angular/core';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {AuthService} from '../../services/authService/auth.service';
import {UtilService} from '../../services/utilService/util.service';
import {ActivatedRoute} from '@angular/router';
import { isNumberKey } from 'src/app/shared/utilities/helpers';
declare const $: any;

@Component({
  selector: 'app-manage-authorizers',
  templateUrl: './manage-authorizers.component.html',
  styleUrls: ['./manage-authorizers.component.css']
})
export class ManageAuthorizersComponent implements OnInit {
  @Input() mode = null;

  public authorizers = [];
  public authorizer = {
    first_name: null,
    last_name: null,
    email: null,
    bvn: null,
    title: null,
    phone_number: null,
    identification_type: null,
    // file_type: null,
    address: null,
    identification_image: null,
    identification_image_type: null
  };
  public types = [];
  public upload = null;
  public loading = false;
  public saving = false;
  public showUpload = false;
  public update = null;
  private currentUser;
  private clientId;
  constructor(private userService: UserService,
              private authService: AuthService,
              private utilService: UtilService,
              private route: ActivatedRoute,
              private bootstrapNotifyService: BootstrapNotifyService) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    if (!this.mode) {
      this.currentUser = this.authService.getUserDetails();
      this.getAuthorizers(this.currentUser.client.id);
      console.log('currentUser', this.currentUser);
      this.types = [
        {id: 'international_passport', value: 'International passport'},
        {id: 'driving_license', value: 'Driver License'},
        {id: 'id_card', value: 'ID card'},
        {id: 'national_id', value: 'National Id'}
      ];
    } else {
      // handle new api calls
      this.getAuthorizers(this.clientId);
    }
  }


  public getAuthorizers(clientId = this.currentUser.client.id) {
    this.loading = true;
    this.userService.getAuthorizersData(clientId).subscribe((res) => {
      this.loading = false;
      console.log('Res', res);
      this.authorizers = res.data;
    }, error => {
      console.log('Error ', error);
      this.loading = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to list authorizers!');
    });
  }
  public createAuthorizer() {
   /* if (!this.authorizer.first_name) {
      return  this.bootstrapNotifyService.info('First name is required');
    } else if (!this.authorizer.last_name) {
      return  this.bootstrapNotifyService.info('Last name is required');
    } else if (!this.authorizer.email) {
      return  this.bootstrapNotifyService.info('Email is required');
    } else if (!this.authorizer.bvn) {
      return  this.bootstrapNotifyService.info('BVN is required');
    } else if (!this.authorizer.identification_type) {
      return  this.bootstrapNotifyService.info('Identification type is required');
    } else*/ if (this.update) {
      this.updateAuthorizer();
    } else {
      /*if (!this.upload) {
        return  this.bootstrapNotifyService.info('You need to upload an attachment');
      }*/
      this.saving = true;
      if (this.upload) {
        this.authorizer.identification_image = this.upload.split(';base64,')[1];
      }
      // this.authorizer.file_type = this.upload.split(';base64,')[0];
      this.userService.createAuthorizer(this.authorizer).subscribe((res) => {
        this.saving = false;
        console.log('Res', res);
        this.bootstrapNotifyService.success('Authorizer created successfully');
        this.resetForm();
        this.getAuthorizers();
        this.showUploads();
      }, error => {
        this.saving = false;
        this.bootstrapNotifyService.error(error.error.message || 'Unable to create authorizer');
      });
    }
  }

  public deleteAuthorizer(authorizer) {
    this.utilService.confirmAction(() => {
      this.userService.deleteAuthorizer(authorizer.id).subscribe((res) => {
        console.log('Res', res);
        this.bootstrapNotifyService.success('Authorizer deleted successfully');
        this.getAuthorizers();
      }, error => {
        this.bootstrapNotifyService.error(error.error.message || 'Unable to delete authorizer');
      });
    });
  }
  private resetForm() {
    this.authorizer = {
      first_name: null,
      last_name: null,
      title: null,
      phone_number: null,
      email: null,
      bvn: null,
      identification_type: null,
      address: null,
      identification_image: null,
      identification_image_type: null
    };
  }

  public printFile(url, name) {
    this.utilService.printDocument(url, name, 'image');
  }

  public verify(doc, type) {
    this.utilService.confirmAction(() => {
      this.userService.toggleAuthorizer(doc.id, {status: type === 'verify' ? 1 : 0 }).subscribe((res) => {
        console.log('Res', res);
        this.getAuthorizers(this.clientId);
        this.bootstrapNotifyService.success(res.message);
      }, error => {
        // console.log('ERROR', error);
        this.bootstrapNotifyService.error(error.error.message ||  'Unable to ' + type + ' authorizer');
      });
    });
  }

  public numberOnly(id) {
    // Get element by id which passed as parameter within HTML element event
    const element: any = document.getElementById(id);
    // This removes any other character but numbers as entered by user
    element.value = element.value.replace(/[^0-9]/gi, '');
  }

  public getTargetFile(e) {
    const file = e.target.files[0];
    this.authorizer.identification_image_type = file.type.split('/')[1];
    const reader = new FileReader();
    reader.onloadend = (() => {
      this.upload = reader.result;
    });
    reader.readAsDataURL(file);
  }
  public allowUpload() {
    $('#displayAuthorizers').addClass('d-none');
    this.showUpload = true;
    this.upload = this.update = null;
    this.resetForm();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
  }
  public showUploads() {
    $('#displayAuthorizers').removeClass('d-none');
    this.showUpload = false;
  }
  public updateAuthorizer() {
    if (this.upload) {
      this.authorizer.identification_image = this.upload.split(';base64,')[1];
    }
    this.saving = true;
    this.userService.updateAuthorizer(this.authorizer, this.update.id).subscribe((res) => {
      this.saving = false;
      console.log('Res', res);
      this.bootstrapNotifyService.success('Authorizer updated successfully');
      this.resetForm();
      this.showUploads();
      this.getAuthorizers();
    }, error => {
      this.saving = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to update authorizer');
    });
  }
  public editAuthorizer(authorizer) {
    this.update = this.authorizer = JSON.parse(JSON.stringify(authorizer));
    $('#displayAuthorizers').addClass('d-none');
    this.showUpload = true;
  }

  
  /**
   *
   * @param event
   */
   public isNumber(event): void {
    return isNumberKey(event);
  }
}
