import { Component, OnInit } from '@angular/core';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {AuthService} from '../../services/authService/auth.service';
import {UtilService} from '../../services/utilService/util.service';
import {ActivatedRoute} from '@angular/router';
declare const $: any;

@Component({
  selector: 'app-other-files',
  templateUrl: './other-files.component.html',
  styleUrls: ['./other-files.component.css']
})
export class OtherFilesComponent implements OnInit {

  public otherFiles = [];
  public otherFile = {
    name: null,
    comment: null,
    file: null,
    file_type: null,
    client_id: null
  };
  public types = [];
  public upload = null;
  public loading = false;
  public saving = false;
  public showUpload = false;
  public update = null;
  private clientId;
  constructor(private userService: UserService,
              private authService: AuthService,
              private utilService: UtilService,
              private route: ActivatedRoute,
              private bootstrapNotifyService: BootstrapNotifyService) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.getOtherFiles(this.clientId);
  }


  public getOtherFiles(clientId = this.clientId) {
    this.loading = true;
    this.userService.getOtherFiles(clientId).subscribe((res) => {
      this.loading = false;
      console.log('Res', res);
      this.otherFiles = res.data;
    }, error => {
      console.log('Error ', error);
      this.loading = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to list files!');
    });
  }
  public createOtherFile() {
    if (!this.otherFile.name) {
      return  this.bootstrapNotifyService.info('File name is required');
    } else if (this.update) {
      if (!this.upload) {
        return  this.bootstrapNotifyService.info('You need to upload an attachment');
      }
      this.otherFile.file = this.upload.split(';base64,')[1];
      this.updateOtherFile();
    } else {
      if (!this.upload) {
        return  this.bootstrapNotifyService.info('You need to upload an attachment');
      }
      this.saving = true;
      this.otherFile.file = this.upload.split(';base64,')[1];
      this.userService.createOtherFile({
        client_id: this.clientId,
        name: this.otherFile.name,
        comment: this.otherFile.comment,
        file: this.otherFile.file,
        file_type: this.otherFile.file_type
      }).subscribe((res) => {
        this.saving = false;
        console.log('Res', res);
        this.bootstrapNotifyService.success('File created successfully');
        this.resetForm();
        this.getOtherFiles();
        this.showUploads();
      }, error => {
        this.saving = false;
        this.bootstrapNotifyService.error(error.error.message || 'Unable to create file');
      });
    }
  }

  public deleteOtherFile(otherFile) {
    this.utilService.confirmAction(() => {
      this.userService.deleteOtherFile(otherFile.id).subscribe((res) => {
        console.log('Res', res);
        this.bootstrapNotifyService.success('File deleted successfully');
        this.getOtherFiles();
      }, error => {
        this.bootstrapNotifyService.error(error.error.message || 'Unable to delete file');
      });
    });
  }
  private resetForm() {
    this.otherFile = {
      name: null,
      comment: null,
      file: null,
      file_type: null,
      client_id: null
    };
  }

  public printFile(url, name) {
    this.utilService.printDocument(url, name, 'image');
  }


  public getTargetFile(e) {
    const file = e.target.files[0];
    this.otherFile.file_type = file.type.split('/')[1];
    const reader = new FileReader();
    reader.onloadend = (() => {
      this.upload = reader.result;
    });
    reader.readAsDataURL(file);
  }
  public allowUpload() {
    $('#displayOtherFiles').addClass('d-none');
    this.showUpload = true;
    this.upload = this.update = null;
    this.resetForm();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
  }
  public showUploads() {
    $('#displayOtherFiles').removeClass('d-none');
    this.showUpload = false;
  }
  public updateOtherFile() {
    this.saving = true;
    this.userService.updateOtherFile(this.otherFile, this.update.id).subscribe((res) => {
      this.saving = false;
      console.log('Res', res);
      this.bootstrapNotifyService.success('File updated successfully');
      this.resetForm();
      this.showUploads();
      this.getOtherFiles();
    }, error => {
      this.saving = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to update file');
    });
  }
  public editOtherFile(otherFile) {
    this.update = this.otherFile = JSON.parse(JSON.stringify(otherFile));
    $('#displayOtherFiles').addClass('d-none');
    this.showUpload = true;
  }
}

