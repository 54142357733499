import { Component, OnInit } from '@angular/core';
import {EventsService} from '../../services/eventServices/event.service';
import {UserService} from "../../services/api-handlers/userService/user.service";
declare const $: any;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  public dashboard
  constructor(private eventService: EventsService, private userService: UserService) {
    this.getDashboard();
  }

  ngOnInit() {
    this.eventService.broadcast('BREADCRUMB', 'Dashboard');
  }
  private getDashboard() {
    this.userService.showDashboard().subscribe((res) => {
      console.log('Res', res);
      this.dashboard = res.data;
    }, error => {
      console.log('Error ', error);
    });
  }
}
