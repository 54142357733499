<div class="content">
  <div class="container-fluid">
    <div class="row mt-4" id="viewClients">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <i class="material-icons">assignment</i>
            </div>
            <div class="float-right">
              <button type="button" (click)="toggleView()" class="btn btn-primary">Create Client<div class="ripple-container"></div></button>
            </div>
          </div>
          <div class="card-body">
            <!--<div class="row">-->
              <div class="toolbar">
              <span class="font-weight-bold">List all clients</span>
                <div class="form-group col-6">
                  <label for="filterBy" class="control-label">Filter by</label>
                  <select class="form-control" (change)="filterView()" id="filterBy" [(ngModel)]="showView" [ngModelOptions]="{standalone: true}">
                    <option value="all">All</option>
                    <option  *ngFor="let type of clientTypes" [value]="type.id">{{type?.name}}</option>
                    <!--<option value="government">Government</option>-->
                  </select>
                </div>
            </div>
            <!--<div class="col-6">

            </div>
            </div>-->



            <div class="material-datatables mt-3">
              <div class="text-center mt-5" *ngIf="loadingTable">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
              </div>
              <div *ngIf="!loadingTable">
                <table  id="listClients" class="table table-striped table-no-bordered table-hover text-center" cellspacing="0" width="100%" style="width:100%">
                  <thead>
                  <tr class="">
                    <th class="disabled-sorting no-sort font-weight-bold">S/N</th>
                    <th class="font-weight-bold">Legal Business Name</th>
                    <!--<th class="font-weight-bold">Last Name</th>-->
                    <th class="font-weight-bold">Email</th>
                    <th class="font-weight-bold">Client Type</th>
                    <th class="font-weight-bold">Risk Rating</th>
                    <th class="font-weight-bold">Status</th>
                    <th class="disabled-sorting text-center no-sort" width="25%"></th>
                  </tr>
                  </thead>
                  <tfoot>
                  <tr>
                    <th>S/N</th>
                    <th class="font-weight-bold">Legal Business Name</th>
                    <!--<th class="font-weight-bold">Last Name</th>-->
                    <th class="font-weight-bold">Email</th>
                    <th class="font-weight-bold">Client Type</th>
                    <th class="font-weight-bold">Risk Rating</th>
                    <th class="font-weight-bold">Status</th>

                    <th class="text-center"></th>
                  </tr>
                  </tfoot>
                  <tbody *ngIf="clients.length > 0">
                  <tr *ngFor="let client of clients;index as i">
                    <td>{{ i + 1}}</td>
                    <td>{{client?.first_name}}</td>
                    <!--<td>{{client?.last_name}}</td>-->
                    <td>{{client?.email}}</td>
                    <td>{{client?.client_type?.name}}</td>
                    <td>{{client?.risk_rating}}</td>
                    <td>
                      <div id="toggle" class="">
                      <label class="switch">
                        <input type="checkbox" [checked]="client?.status == 'ACTIVE'"  (change)="toggleStatus(client)">
                        <span class="slider round"></span>
                      </label>
                    </div></td>
                    <td class="text-center">
                      <a href="javascript:void(0)" (click)="editClient(client)" class="btn btn-link btn-warning btn-just-icon edit"><i class="material-icons">edit</i><div class="ripple-container"></div></a>
                      <a href="javascript:void(0)" (click)="viewDocuments(client)" class="btn btn-link btn-primary"><i class="material-icons">dvr</i>&nbsp;View Details<div class="ripple-container"></div></a>
                    </td>
                  </tr>
                  </tbody>
                  <tbody *ngIf="clients.length < 1">
                  <tr ><td colspan="3">No client available!</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>











    <!--Create client-->
    <div class="row mt-4" *ngIf="formPage">
      <div class="col-md-6 col-sm-12 offset-md-3">
        <div class="card">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <i class="material-icons">people</i>
            </div>
          </div>
          <form novalidate (ngSubmit)="saveClient()">
            <div class="card-body">
              <div class="toolbar">
                {{createdDisplay.title}}
              </div>
              <div class="form-group bmd-form-group mb-3 mt-3">
                <label for="business_name" class="">Full Legal Business Name *</label>
                <input type="text" class="form-control" id="business_name" required name="business_name" [(ngModel)]="client.business_name" [ngModelOptions]="{standalone: true}">
              </div>
             <!-- <div class="form-group bmd-form-group mb-3">
                <label for="last_name" class="">Last Name *</label>
                <input type="text" class="form-control" id="last_name" required name="last_name" [(ngModel)]="client.last_name" [ngModelOptions]="{standalone: true}">
              </div>-->
              <div class="form-group bmd-form-group mb-3">
                <label for="email" class="">Client Email Address *</label>
                <input type="email" class="form-control" id="email" [readonly]="updateMode" required name="email" [(ngModel)]="client.email" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group bmd-form-group mb-3">
                <!--<label class="">Client Type *</label>-->
                <ng-select [items]="clientTypes" class="ng-select-form" id="clientType" #clientTypeList [searchable]="false"
                           [notFoundText]="'No matching client type'"
                           bindValue="id"
                           bindLabel="name"
                           [(ngModel)]="client.client_type_id" [ngModelOptions]="{standalone: true}">
                  <ng-template ng-header-tmp>
                    <input style="width: 100%; line-height: 24px" type="text" placeholder="Search..." (input)="clientTypeList.filter($event?.target?.value)"/>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group bmd-form-group mb-3">
                <!--<label class="">Client Type *</label>-->
                <ng-select [items]="riskRatings" class="ng-select-form" id="riskRating" [searchable]="false"
                           placeholder="Select risk rating"
                           bindLabel="name"
                           bindValue="id"
                           [(ngModel)]="client.risk_rating" [ngModelOptions]="{standalone: true}">
                </ng-select>
              </div>
              <div class="category form-category">* Required fields</div>
            </div>
            <div class="card-footer ml-auto mr-auto justify-content-center">
              <button type="submit" class="btn btn-info" [disabled]="!client.business_name || !client.email || !client.client_type_id">{{createdDisplay?.btnTxt}}
                <i class="fa fa-spin fa-spinner" *ngIf="loading"></i>
                <div class="ripple-container"></div>
              </button>
              &nbsp;
              <button type="reset" class="btn btn-secondary" (click)="resetForm()">Cancel
              <div class="ripple-container"></div>
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
