import {Component, Input, OnInit} from '@angular/core';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {AuthService} from '../../services/authService/auth.service';
import {UtilService} from '../../services/utilService/util.service';
import {ActivatedRoute} from '@angular/router';
declare const $: any;

@Component({
  selector: 'app-manage-directors',
  templateUrl: './manage-directors.component.html',
  styleUrls: ['./manage-directors.component.css']
})
export class ManageDirectorsComponent implements OnInit {
  @Input() mode = null;

  public directors = [];
  public director = {
    first_name: null,
    last_name: null,
    email: null,
    bvn: null,
    cac_number : null,
    verification_type: null,
    // file_type: null,
    address: null,
    verification_image: null,
    verification_image_type: null
  };
  public types = [];
  public upload = null;
  public loading = false;
  public saving = false;
  public showUpload = false;
  public update = null;
  private currentUser;
  private clientId;
  constructor(private userService: UserService,
              private authService: AuthService,
              private utilService: UtilService,
              private route: ActivatedRoute,
              private bootstrapNotifyService: BootstrapNotifyService) { }

  ngOnInit(): void {


    this.clientId = this.route.snapshot.paramMap.get('clientId');

    if (!this.mode) {
      this.currentUser = this.authService.getUserDetails();
      this.getDirectors(this.currentUser.client.id);
      console.log('currentUser', this.currentUser);
      this.types = [
        {id: 'international_passport', value: 'International passport'},
        {id: 'driving_license', value: 'Driver License'},
        {id: 'voters_card', value: 'Voters card'},
        {id: 'national_id', value: 'National Id'}
      ];
    } else {
      // handle new api calls
      this.getDirectors(this.clientId);
    }
  }


  public getDirectors(clientId = this.currentUser.client.id) {
    this.loading = true;
    this.userService.getDirectorsData(clientId).subscribe((res) => {
      this.loading = false;
      console.log('Res', res);
      this.directors = res.data;
    }, error => {
      console.log('Error ', error);
      this.loading = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to list directors!');
    });
  }
  public createDirector() {
    /*if (!this.director.first_name) {
      return  this.bootstrapNotifyService.info('First name is required');
    } else if (!this.director.last_name) {
      return  this.bootstrapNotifyService.info('Last name is required');
    } else if (!this.director.email) {
      return  this.bootstrapNotifyService.info('Email is required');
    } else if (!this.director.bvn) {
      return  this.bootstrapNotifyService.info('BVN is required');
    } else if (!this.director.cac_number) {
      return  this.bootstrapNotifyService.info('CAC number is required');
    } else if (!this.director.verification_type) {
      return  this.bootstrapNotifyService.info('Verification type is required');
    } else */if (this.update) {
      this.updateDirector();
    } else {
      /*if (!this.upload) {
        return  this.bootstrapNotifyService.info('You need to upload an attachment');
      }*/
      this.saving = true;
      if (this.upload) {
        this.director.verification_image = this.upload.split(';base64,')[1];
      }
      this.userService.createDirector(this.director).subscribe((res) => {
        this.saving = false;
        console.log('Res', res);
        this.bootstrapNotifyService.success('Director created successfully');
        this.resetForm();
        this.getDirectors();
        this.showUploads();
      }, error => {
        this.saving = false;
        this.bootstrapNotifyService.error(error.error.message || 'Unable to create director');
      });
    }
  }

  public deleteDirector(director) {
    this.utilService.confirmAction(() => {
      this.userService.deleteDirector(director.id).subscribe((res) => {
        console.log('Res', res);
        this.bootstrapNotifyService.success('Director deleted successfully');
        this.getDirectors();
      }, error => {
        this.bootstrapNotifyService.error(error.error.message || 'Unable to delete director');
      });
    });
  }


  public printFile(url, name) {
    this.utilService.printDocument(url, name, 'image');
  }
  private resetForm() {
    this.director = {
      first_name: null,
      last_name: null,
      email: null,
      bvn: null,
      cac_number : null,
      verification_type: null,
      address: null,
      verification_image: null,
      verification_image_type: null
    };
  }

  public verify(doc, type) {
    this.utilService.confirmAction(() => {
      this.userService.toggleDirector(doc.id, {status: type === 'verify' ? 1 : 0 }).subscribe((res) => {
        console.log('Res', res);
        this.getDirectors(this.clientId);
        this.bootstrapNotifyService.success(res.message);
      }, error => {
        // console.log('ERROR', error);
        this.bootstrapNotifyService.error(error.error.message ||  'Unable to ' + type + ' director');
      });
    });
  }

  public getTargetFile(e) {
    const file = e.target.files[0];
    this.director.verification_image_type = file.type.split('/')[1];
    const reader = new FileReader();
    reader.onloadend = (() => {
      this.upload = reader.result;
    });
    reader.readAsDataURL(file);
  }
  public allowUpload() {
    $('#displayDirectors').addClass('d-none');
    this.showUpload = true;
    this.upload = this.update = null;
    this.resetForm();
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
  }
  public showUploads() {
    $('#displayDirectors').removeClass('d-none');
    this.showUpload = false;
  }
  public updateDirector() {
    if (this.upload) {
      this.director.verification_image = this.upload.split(';base64,')[1];
      // this.director.file_type = this.upload.split(';base64,')[0];
    }
    this.saving = true;
    this.userService.updateDirector(this.director, this.update.id).subscribe((res) => {
      this.saving = false;
      console.log('Res', res);
      this.bootstrapNotifyService.success('Director updated successfully');
      this.resetForm();
      this.showUploads();
      this.getDirectors();
    }, error => {
      this.saving = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to update director');
    });
  }
  public editDirector(director) {
    this.update = this.director = JSON.parse(JSON.stringify(director));
    $('#displayDirectors').addClass('d-none');
    this.showUpload = true;
  }

  public numberOnly(id) {
    // Get element by id which passed as parameter within HTML element event
    const element: any = document.getElementById(id);
    // This removes any other character but numbers as entered by user
    element.value = element.value.replace(/[^0-9]/gi, '');
  }
}
