<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <i class="material-icons">people</i>
            </div>
            <p class="card-category">Clients</p>
            <h3 class="card-title">{{dashboard?.total_clients || 0}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <a href="javascript:void(0)" [routerLink]="['/admin/manage-clients']">Manage Clients</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <i class="material-icons">check</i>
            </div>
            <p class="card-category">Verified</p>
            <h3 class="card-title">{{dashboard?.approved_clients || 0}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">table</i> <a href="javascript:void(0)" [routerLink]="['/admin/manage-clients']">View clients</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <i class="material-icons">edit_off</i>
            </div>
            <p class="card-category">Pending Clients</p>
            <h3 class="card-title">{{dashboard?.pending_clients || 0 }}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">people</i>
              <a href="javascript:void(0)" [routerLink]="['/admin/manage-clients']">View clients</a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
