

<div class="" id="displayShareholders">
  <div class="row mb-3 pb-3" *ngIf="shareholders.length && mode !== 'readonly'">
    <div class="col-12">
      <a href="javascript:void(0)" class="btn btn-rose btn-round float-right" *ngIf="!loading" (click)="allowUpload()">
        <i class="material-icons">add</i>&nbsp;
        Create Shareholder<div class="ripple-container"></div></a>
    </div>
  </div>

  <div class="row" *ngIf="!shareholders.length">
    <div class="col-md-12">
      <div class="card" style="min-height: 240px">
        <div class="card-header text-center" *ngIf="!loading">
          <h4 class="card-title mt-5 pt-5">No Shareholder created yet</h4>
        </div>
        <div class="card-body text-center" *ngIf="mode !== 'readonly'">
          <div *ngIf="loading" class="mt-5 pt-5">
            <i class="fa fa-spin fa-spinner fa-2x"></i>&nbsp;Loading...
          </div>
          <a href="javascript:void(0)" class="btn btn-rose btn-round" *ngIf="!loading" (click)="allowUpload()">
            <i class="material-icons">add</i>&nbsp;
            Create Shareholder<div class="ripple-container"></div></a>
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="shareholders.length && !loading">
    <div class="col-md-6" *ngFor="let shareholder of shareholders">
      <div class="card card-product">
        <div class="card-header card-header-image" data-header-animation="true">
          <a href="javascript:void(0)" (click)="allowUpload()">
            <img class="img" [src]="shareholder?.identification_image"  onerror="this.src='../assets/images/file.jpg'" style="min-height: 300px;max-height: 300px">
          </a>
        </div>
        <div class="card-body">
          <div class="card-actions text-center" *ngIf="mode !== 'readonly'">
            <button type="button" class="btn btn-success btn-link" (click)="editShareholder(shareholder)" >
              <i class="material-icons">edit</i> Edit
            </button>
            <button type="button" (click)="deleteShareholder(shareholder)" class="btn btn-danger btn-link">
              <i class="material-icons">close</i> Delete
            </button>
          </div>

          <div  class="card-actions text-center" *ngIf="mode === 'readonly'">

            <button type="button" class="btn btn-success btn-link" (click)="verify(shareholder, 'verify')" *ngIf="shareholder.identification_status === 'NOT_VERIFIED'">Verify</button>
            <button type="button" class="btn btn-warning btn-link" (click)="verify(shareholder, 'unverify')" *ngIf="shareholder.identification_status === 'VERIFIED'">Unverify</button>
            <button type="button" class="btn btn-info btn-link" (click)="printFile(shareholder?.identification_image, 'Shareholder')">Print</button>
          </div>

          <h4 class="card-title text-left">
            <a href="javascript:void(0)">Name: {{shareholder?.first_name + ", " + shareholder?.last_name}}</a>
            <div style="font-size: 10px; padding-top: -10px">BVN: {{shareholder?.bvn}}</div>

          </h4>
          <h2 class="card-title text-left">
            <div style="font-size: 10px; padding-top: -10px">STATUS:
              <span class="badge badge-success" *ngIf="shareholder.identification_status === 'VERIFIED'">VERIFIED</span>
              <span class="badge badge-info" *ngIf="shareholder.identification_status === 'NOT_VERIFIED'">PENDING</span></div>
          </h2>
          <div class="card-description text-left">
            Address: {{shareholder?.address}}
          </div>
        </div>
        <div class="card-footer">
          <div class="stats">
            <small>{{shareholder?.identification_type}}</small>
          </div>
          <div class="stats">
            <p class="card-category">{{ shareholder?.email}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showUpload && mode !== 'readonly'">
  <div class="card">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">assignment</i>
      </div>
      <h4 class="card-title">Shareholder
      </h4>
    </div>



    <div class="card-body">
      <form novalidate (ngSubmit)="createShareholder()">
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group bmd-form-group">
              <label class="bold-label">First Name</label>
              <input type="text" class="form-control" [(ngModel)]="shareholder.first_name"
                     [ngModelOptions]="{standalone: true}">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group bmd-form-group">
              <label class="bold-label">Last Name</label>
              <input type="text" class="form-control" [(ngModel)]="shareholder.last_name"
                     [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">Email</label>
              <input type="email" class="form-control"
                     [(ngModel)]="shareholder.email"
                     [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">BVN</label>
              <input type="text" class="form-control" (input)="numberOnly('bvn_shareholder');"  id="bvn_shareholder" [(ngModel)]="shareholder.bvn" maxlength="11"
                     [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">Address</label>
              <input type="text" class="form-control" [(ngModel)]="shareholder.address"
                     [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-md-6 col-sm-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">Identification type</label><br>
              <select class="selectpicker" data-style="btn btn-rose" title="Select identification type"  [(ngModel)]="shareholder.identification_type"
                      [ngModelOptions]="{standalone: true}">
                <option disabled selected="">Select Identification type</option>
                <option *ngFor="let type of types" [value]="type.id">{{type.value}}</option>
              </select>
            </div>
            <div class="font-weight-bold text-rose" style="font-size: 9px;">Upload recent means of identification</div>

          </div>
          <div class="col-md-6 col-sm-12">
            <div class="mt-2">
              <label class="bold-label">Upload File</label><br>
              <!--<input type="file"  (change)="getTargetFile($event)">-->
              <input type="file" accept="application/pdf,image/png,image/jpg,image/jpeg" (change)="getTargetFile($event)">
              <div style="font-size: 10px" class="text-rose">Accepted format: PNG, JPG, JPEG, PDF</div>
              <a [href]="shareholder?.identification_image" *ngIf="shareholder.identification_image && !upload" target="_blank" style="font-size: 9px">{{shareholder?.identification_image}}</a>
            </div>
          </div>

        </div>
        <button type="button" *ngIf="!saving" class="btn btn-default pull-right" (click)="showUploads()">Cancel</button>&nbsp;
        <button type="submit" *ngIf="!saving" class="btn btn-rose pull-right">{{ update ? "Update" : "Create" }}</button>
        <button type="button" *ngIf="saving" class="btn btn-rose pull-right"><i class="fa fa-spinner fa-spin"></i>&nbsp;Please wait!</button>
        <div class="clearfix"></div>
      </form>
    </div>

  </div>

</ng-container>
