import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/authService/auth.service';
import {EventsService} from '../../services/eventServices/event.service';
import {ActivatedRoute} from '@angular/router';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {UserService} from "../../services/api-handlers/userService/user.service";
@Component({
  selector: 'app-manage-client-documents',
  templateUrl: './manage-client-documents.component.html',
  styleUrls: ['./manage-client-documents.component.css']
})
export class ManageClientDocumentsComponent implements OnInit {
  public documentTab = false;
  public directorTab = false;
  public shareholderTab = false;
  public otherTab = false;
  public authorizerTab = false;
  public currentUser;
  public formSteps = [];
  public client;
  loading = true;
  constructor(private authService: AuthService, private eventService: EventsService,
              private userService: UserService,
              private route: ActivatedRoute, private navigatorService: NavigatorService) {
    // this.currentUser = this.authService.getUserDetails();
    const clientId = this.route.snapshot.paramMap.get('clientId');
    this.getClient(clientId);
    // todo: User client Id to get client info
  }

  ngOnInit() {
    this.eventService.broadcast('BREADCRUMB', 'Client Profile');
  }

  private getClient(clientId) {
    this.userService.showClient(clientId).subscribe((res) => {
      console.log('Res', res);
      this.client = res.data;
      this.formSteps = this.client.client_type.form_steps;
      this.loading = false;
    }, error => {
      console.log('Error ', error);
      this.loading = false;
    });
  }

  public showClients() {
    this.navigatorService.navigateUrl('/admin/manage-clients');
  }

  public isIncluded(step) {
    return this.formSteps.includes(step);
  }
}
