<div class="card">
  <div class="card-header card-header-rose card-header-icon">
    <div class="card-icon">
      <i class="material-icons">vpn_key</i>
    </div>
    <h4 class="card-title">Change my password</h4>
  </div>
  <form novalidate (ngSubmit)="updatePassword()">
    <div class="card-body mt-3">

      <div class="form-group bmd-form-group">
        <label for="input-password" class="bmd-label-floating">Previous Password</label>
        <input  type="password" id="input-password" class="form-control" required
                [(ngModel)]="passwordCredential.old_password" #password="ngModel"
                [ngModelOptions]="{standalone: true}">
      </div>
      <div class="form-group bmd-form-group">
        <label for="input-new-password" class="bmd-label-floating">New Password</label>
        <input type="password" id="input-new-password" class="form-control" [disabled]="!passwordCredential.old_password"
               [(ngModel)]="passwordCredential.password" #new_password="ngModel" required minlength="7"
               [ngModelOptions]="{standalone: true}">
        <div class="text-danger"  *ngIf="new_password.errors && (new_password.dirty || new_password.touched)" style="margin-top: -5px;">
                  <span class="validation-message" [hidden]="!new_password.hasError('required')">
                 Password is required
                  </span>
          <span class="validation-message" [hidden]="!new_password.hasError('minlength')">
                 Password is too short
                  </span>
        </div>
      </div>
      <div class="form-group bmd-form-group">
        <label for="input-confirm-password" class="bmd-label-floating">Confirm New Password</label>
        <input type="password" id="input-confirm-password" class="form-control" [disabled]="!passwordCredential.password"
               [(ngModel)]="passwordCredential.password_confirmation" #confirm_password="ngModel" required minlength="7"
               [ngModelOptions]="{standalone: true}">
        <div class="text-danger"  *ngIf="confirm_password.dirty || confirm_password.touched" style="margin-top: -5px;">
                  <span class="validation-message" [hidden]="passwordCredential.password === passwordCredential.password_confirmation ">
                 Password must match</span>
        </div>

      </div>



    </div>
    <div class="card-footer justify-content-center">
      <button class="btn btn-fill btn-rose" type="submit"  *ngIf="!loading" [disabled]="password.errors  || new_password.errors || confirm_password.errors|| passwordCredential.confirm_password !== passwordCredential.new_password">Update Password</button>
      <button class="btn btn-fill btn-rose" type="button" disabled *ngIf="loading"><i class="fa fa-spin fa-spinner"></i></button>
    </div>
  </form>
</div>
