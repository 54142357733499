
<!--
<h3>Documents List</h3>
<br>
-->
<div class="" id="displayDocuments">
<div class="row mb-3 pb-3" *ngIf="documents.length && mode !== 'readonly'">
  <div class="col-12" >
    <a href="javascript:void(0)" class="btn btn-rose btn-round float-right" *ngIf="!loading"  (click)="allowUpload()">
  <i class="material-icons">add</i>&nbsp;
  Upload Documents<div class="ripple-container"></div></a>
  </div>
</div>

<div class="row" *ngIf="!documents.length">
  <div class="col-md-12">
    <div class="card" style="min-height: 240px">
      <div class="card-header text-center" *ngIf="!loading">
        <h4 class="card-title mt-5 pt-5">No Document uploaded yet</h4>
      </div>
      <div class="card-body text-center" *ngIf="mode !== 'readonly'">
        <div *ngIf="loading" class="mt-5 pt-5">
          <i class="fa fa-spin fa-spinner fa-2x"></i>&nbsp;Loading...
        </div>
        <a href="javascript:void(0)" class="btn btn-rose btn-round" *ngIf="!loading" (click)="allowUpload()">
          <i class="material-icons">add</i>&nbsp;
          Upload Documents<div class="ripple-container"></div></a>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="documents.length && !loading">
  <div class="col-md-6" *ngFor="let document of documents">
  <div class="card card-product">
    <div class="card-header card-header-image" data-header-animation="true">
      <a href="javascript:void(0)" (click)="allowUpload()">
        <img class="img" [src]="document?.image"  onerror="this.src='../assets/images/file.jpg'" style="min-height: 300px;max-height: 300px">
      </a>
    </div>
    <div class="card-body">
      <div class="card-actions text-center" *ngIf="mode !== 'readonly'">
       <!-- <button type="button" class="btn btn-success btn-link" rel="tooltip" data-placement="bottom" title="" data-original-title="Edit">
          <i class="material-icons">edit</i> Edit
        </button>-->
        <button type="button" (click)="deleteUploadedDoc(document)" class="btn btn-danger btn-link" rel="tooltip" data-placement="bottom" title="" data-original-title="Remove">
          <i class="material-icons">close</i> Delete
        </button>
      </div>
      <div  class="card-actions text-center" *ngIf="mode === 'readonly'">
        <button type="button" class="btn btn-success btn-link" (click)="verify(document, 'verify')" *ngIf="document.status === 'NOT_VERIFIED'">Verify</button>
        <button type="button" class="btn btn-warning btn-link" (click)="verify(document, 'unverify')" *ngIf="document.status === 'VERIFIED'">Unverify</button>

        <button type="button" class="btn btn-info btn-link" (click)="printFile(document?.image, document?.name)">Print</button>
      </div>
      <h4 class="card-title">
        <a href="javascript:void(0)" class="text-capitalize">{{document?.name?.split('_').join(' ')}}</a>
      </h4>

      <h2 class="card-title text-left">
        <div style="font-size: 10px; padding-top: -10px">STATUS:
          <span class="badge badge-success" *ngIf="document.status === 'VERIFIED'">VERIFIED</span>
          <span class="badge badge-info" *ngIf="document.status === 'NOT_VERIFIED'">PENDING</span></div>
      </h2>
    </div>
  </div>
</div>
</div>
</div>
<ng-container *ngIf="showUpload && mode !== 'readonly'">
  <div class="card">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">assignment</i>
      </div>
      <h4 class="card-title">Documents -
        <small class="category">Browse and upload documents</small>
      </h4>
    </div>
    <div class="card-body">
      <!--<div class="row">
        <div class="col-12">
          <select class="selectpicker" data-style="btn btn-rose" title="Select document type" [(ngModel)]="newDoc"
                  [ngModelOptions]="{standalone: true}" (change)="addNewDocument($event)">
            <option disabled selected="">Select document type</option>
            <option *ngFor="let type of types" [value]="type.id">{{type.value}}</option>
          </select>
        </div>
      </div>-->


      <div class="row mt-3" *ngFor="let type of types; index as i">
        <!--<div class="col-md-1">{{i + 1}}</div>-->
        <div class="col-md-6 font-weight-bold text-capitalize">{{type.id.split('_').join(' ')}}</div>
        <div class="col-md-5">
          <div class="picture-container">
              <input type="file" accept="application/pdf,image/png,image/jpg,image/jpeg" (change)="getTargetFile($event, type)">
            <div style="font-size: 10px" class="text-rose">Accepted format: PNG, JPG, JPEG, PDF</div>
          </div>
        </div>
        <!--<div class="col-md-2" >
          <button type="button"  class="btn btn-danger btn-link" style="margin-top: -10px" (click)="removeSelectedDocument()">
            <i class="material-icons">close</i>
          </button>
        </div>-->


      </div>

      <div class="mt-4">
        <button type="button" *ngIf="!saving" class="btn btn-default pull-right" (click)="showUploads()">Cancel</button>&nbsp;
        <button type="submit" *ngIf="!saving" class="btn btn-rose pull-right" (click)="saveDocuments()">Save Documents</button>
      <button type="button" *ngIf="saving" class="btn btn-rose pull-right"><i class="fa fa-spinner fa-spin"></i>&nbsp;Please wait!</button>
      <div class="clearfix"></div></div>
    </div>
  </div>

</ng-container>
