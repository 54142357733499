import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/authService/auth.service';
import {EventsService} from '../../services/eventServices/event.service';
import {CacheService} from '../../services/cacheService/cache.service';
import {environment as ENV } from '../../../environments/environment';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
public documentTab = false;
public directorTab = false;
public shareholderTab = false;
public authorizerTab = false;
public currentUser;
public formSteps = [];
  constructor(private authService: AuthService, private eventService: EventsService, private cacheService: CacheService) {
    this.currentUser = this.authService.getUserDetails();
    console.log('Current User', this.currentUser);
    this.formSteps = this.currentUser.form_steps;
    this.eventService.on('PROFILE_UPDATED', (data, profile) => {
      console.log('Data', data);
      const newProfile = {
        ...this.currentUser,
        status: data, first_name: profile.first_name,
        last_name: profile.last_name,
        login: {...this.currentUser.client, ...profile}};
      this.cacheService.setSession(ENV.USERTOKEN, newProfile);
      this.currentUser = this.authService.getUserDetails();
      this.eventService.broadcast('PROFILE_UPDATE', null);
    });
  }

  ngOnInit() {
    this.eventService.broadcast('BREADCRUMB', 'Profile');
  }
  public isIncluded(step) {
    return this.formSteps.includes(step);
  }

  public browseImage() {
    $('#uploadFile').trigger('click');
  }
  public uploadPicture(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (() => {
      this.currentUser.profile_img = reader.result;
    });
    reader.readAsDataURL(file);
  }
}
