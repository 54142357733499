<div class="content mt-4">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-default btn-sm" (click)="showClients()">Back</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">

        <div class="card" *ngIf="loading" style="min-height: 200px">
          <div class="text-center mt-5">
            <i class="fa fa-spin fa-spinner fa-3x"></i><br/>
            <span>Loading client uploads...</span>
          </div>
        </div>
        <div *ngIf="!loading" class="page-categories">
          <!--<h5 class="title text-center">Menu</h5>-->
          <ul class="nav nav-pills nav-pills-warning nav-pills-icons justify-content-center" role="tablist">
            <li class="nav-item mb-1">
              <a class="nav-link active" data-toggle="tab" href="#myProfile" role="tablist">
                <i class="material-icons">info</i> Client Profile
              </a>
            </li>
            <li class="nav-item mb-1" *ngIf="isIncluded('documents_uploads')">
              <a class="nav-link" data-toggle="tab" href="#documents" role="tablist" (click)="documentTab = true">
                <i class="material-icons">assignment</i> Documents
              </a>
            </li>
            <li class="nav-item mb-1"  *ngIf="isIncluded('directors_uploads')">
              <a class="nav-link" data-toggle="tab" href="#directors" role="tablist" (click)="directorTab = true">
                <i class="material-icons">group</i> Directors
              </a>
            </li>
            <li class="nav-item mb-1" *ngIf="isIncluded('shareholders_uploads')">
              <a class="nav-link" data-toggle="tab" href="#shareHolders" role="tablist"  (click)="shareholderTab = true">
                <i class="material-icons">group_add</i> Shareholders
              </a>
            </li>
            <li class="nav-item mb-1" *ngIf="isIncluded('authorizers_uploads') || isIncluded('authorizers_upload')">
              <a class="nav-link" data-toggle="tab" href="#authorizer" role="tablist" (click)="authorizerTab = true">
                <i class="material-icons">verified_user</i> Authorizers</a>
            </li>
            <li class="nav-item mb-1">
              <a class="nav-link" data-toggle="tab" href="#others" role="tablist" (click)="otherTab = true">
                <i class="material-icons">folder</i> Others
              </a>
            </li>
          </ul>
          <div class="tab-content tab-space tab-subcategories">
            <div class="tab-pane active" id="myProfile">
              <div class="container">
                <app-personal-data [mode]="'readonly'"></app-personal-data>
              </div>
            </div>
            <div class="tab-pane" id="documents">
              <div class="container"><app-manage-documents [mode]="'readonly'" *ngIf="documentTab"></app-manage-documents></div>
            </div>
            <div class="tab-pane" id="directors">
              <div class="container"><app-manage-directors [mode]="'readonly'" *ngIf="directorTab"></app-manage-directors></div>
            </div>
            <div class="tab-pane" id="shareHolders">
              <div class="container"><app-manage-shareholders [mode]="'readonly'" *ngIf="shareholderTab"></app-manage-shareholders></div>
            </div>
            <div class="tab-pane" id="authorizer">
              <div class="container"><app-manage-authorizers [mode]="'readonly'" *ngIf="authorizerTab"></app-manage-authorizers></div>
            </div>
            <div class="tab-pane" id="others">
              <div class="container"><app-other-files [mode]="'readonly'" *ngIf="otherTab"></app-other-files></div>
            </div>
          </div>
        </div>


      </div>
      <div class="col-md-4">
        <div class="card card-profile" *ngIf="!loading">
          <div class="card-avatar">
            <a href="javascript:void(0)">
              <img class="img" src="assets/images/user-img.jpg">
            </a>
          </div>
          <div class="card-body">
            <h6 class="card-category text-gray">{{client?.email}}</h6>
            <h4 class="card-title">{{client?.first_name}}</h4>
            <h6 class="card-title">Status: {{client?.status}}</h6>
            <!--<p class="card-description">
              Client Type: {{ client?.client_type?.name}}
            </p>-->
          </div>
        </div>
      </div>
    </div>



  </div>
</div>
