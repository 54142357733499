import { Component, OnInit } from '@angular/core';
import {EventsService} from '../../services/eventServices/event.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  constructor(private eventService: EventsService){}
  ngOnInit(): void {
    this.eventService.broadcast('BREADCRUMB', 'Change Password');
  }
}
