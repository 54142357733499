import { Component, OnInit } from '@angular/core';
import {EventsService} from '../../services/eventServices/event.service';
import {IResponse} from '../../interfaces/iresponse';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../services/utilService/util.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-manage-clients',
  templateUrl: './manage-clients.component.html',
  styleUrls: ['./manage-clients.component.css']
})
export class ManageClientsComponent implements OnInit {
  public loadingTable = false;
  public formPage = false;
  public loading = false;
  public clients: any[] = [];
  public clientTypes: any[] = [];
  public client = {
    business_name: null,
    first_name: null,
    last_name: null,
    client_type_id: null,
    email: null,
    risk_rating: null
  };
  private updateMode: any;
  public createdDisplay = {
    title: 'Create new client',
    btnTxt: 'Save client'
  };
  public riskRatings = [
    {name: 'High', id: 'high'},
    {name: 'Medium', id: 'medium'},
    {name: 'Low', id: 'low'},
  ];
  showView = 'all';
  constructor(private eventService: EventsService, private userService: UserService,
              private utilService: UtilService,
              private router: Router,
              private bootstrapNotifyService: BootstrapNotifyService) { }

  ngOnInit(): void {
    this.eventService.broadcast('BREADCRUMB', 'Manage Clients');
    this.getClients();
    this.getClientTypes();
  }


  public getClients(): void {
    this.loadingTable  = true;
    this.userService.getClients().subscribe((res: IResponse) => {
      this.clients =  res.data.data;
      this.loadingTable = false;
      this.utilService.startDatatable('listClients');
    }, error => {
      console.log('E ', error);
      this.bootstrapNotifyService.error(error.error.message || 'Unable to get clients');
      this.loadingTable = false;
    });
  }
  public getClientsByType(typeId): void {
    this.loadingTable  = true;
    this.userService.getClients(typeId).subscribe((res: IResponse) => {
      this.clients =  res.data.data;
      this.loadingTable = false;
      this.utilService.startDatatable('listClients');
    }, error => {
      console.log('E ', error);
      this.bootstrapNotifyService.error(error.error.message || 'Unable to get clients');
      this.loadingTable = false;
    });
  }
  public resetForm() {
    this.toggleView();
    this.client = {
      business_name: null,
      // last_name: null,

      first_name: null,
      last_name: null,
      client_type_id: null,
      risk_rating: null,
      email: null
    };
    this.updateMode = null;
    this.createdDisplay = {
      title: 'Create new client',
      btnTxt: 'Save client'
    };
  }
  public editClient(client: any) {
    this.client = this.updateMode = JSON.parse(JSON.stringify(client));
    this.createdDisplay = {
      title: 'Update client',
      btnTxt: 'Save Client'
    };
    this.toggleView();
  }
  public viewDocuments(client: any) {
    this.router.navigateByUrl(`/admin/client/${client.id}/manage-documents`);
  }

  public saveClient() {
    if (!this.client.business_name) {
      return this.bootstrapNotifyService.info('Client business/company name is required!');
    }/* else if (!this.client.last_name) {
      return this.bootstrapNotifyService.info('Client last name is required!');
    }*/ else if (!this.client.email) {
      return this.bootstrapNotifyService.info('Client email is required!');
    } else if (!this.client.client_type_id) {
      return this.bootstrapNotifyService.info('Client type is required!');
    } else if (this.updateMode) {
      this.updateClient();
    } else {
      this.createClient();
    }
  }
  private createClient() {
    this.loading = true;
    this.client.first_name = this.client.last_name = this.client.business_name;
    this.userService.createClient(this.client).subscribe((res) => {
      this.loading = false;
      this.resetForm();
      this.bootstrapNotifyService.success('Client created!');
      this.getClients();
    }, error => {
      this.loading =  false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to create client!');
    });
  }
  private updateClient() {
    this.loading = true;
    this.userService.updateClient(this.client, this.updateMode.id).subscribe((res) => {
      this.loading = false;
      console.log('Res', res);
      this.bootstrapNotifyService.success('Client updated!');
      this.resetForm();
      this.getClients();
    }, error => {
      this.loading =  false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to update client!');
    });
  }
  private getClientTypes() {
    this.userService.getClientTypes().subscribe((res: IResponse) => {
      this.clientTypes =  res.data.data;
    }, error => {
      this.bootstrapNotifyService.error(error.error.message || 'Unable to list client types');
    });
  }
  public toggleView() {
    if ($('#viewClients').hasClass('d-none')) {
      $('#viewClients').removeClass('d-none');
      this.formPage = false;
    } else {
      $('#viewClients').addClass('d-none');
      this.formPage = true;
    }
  }

  public toggleStatus(client) {
    this.userService.toggleClient({
      status: client.status.toLowerCase() === 'active' ? 'inactive' : 'active',
      client_id: client.id}).subscribe(response => {
      this.getClients();
      this.bootstrapNotifyService.success('Client status toggled successfully!');
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotifyService.error(error.error.message || 'Unable to toggle client status!');
      this.getClients();
    });
  }



  public filterView() {
    if (this.showView === 'all') {
      this.getClients();
    } else {
      this.getClientsByType(this.showView);
    }
  }

}
