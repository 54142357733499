/**
 * Created by Arokoyu Olalekan Ojo
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {IResponse} from '../../../interfaces/iresponse';
import {ApiService} from '../../api/api.service';
import {map} from 'rxjs/operators';
@Injectable()
export class UserService {

  constructor(private api: ApiService) {

  }

  auth(data: any): Observable<IResponse> {
    return this.api.postRequest('auth', 'login' , data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  logout(): Observable<IResponse> {
    return this.api.getRequest('auth', 'logout').pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  forgotPassword(data: any): Observable<IResponse> {
    return this.api.postRequest('forgot-password', null , data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  resetPassword(data: any): Observable<IResponse> {
    return this.api.postRequest('forgot-password', 'reset' , data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  changePassword(data: any): Observable<IResponse> {
    return this.api.postRequest('password', 'change' , data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getClientTypes(): Observable<IResponse> {
    return this.api.getRequest('admin', 'client_type').pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getClients(typeId?: string): Observable<IResponse> {
    if (typeId) {
      return this.api.getRequest('admin', `client?client_type_id=${typeId}&&status=enable&&limit=10000000`).pipe(
        map((res: IResponse) => {
          return res;
        })
      );
    } else {
      return this.api.getRequest('admin', 'client?limit=10000000').pipe(
        map((res: IResponse) => {
          return res;
        })
      );
    }
  }
  createClientType(data: object): Observable<IResponse> {
    return this.api.postRequest('admin', 'client_type', data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  createClient(data: object): Observable<IResponse> {
    return this.api.postRequest('admin', 'client', data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateClientType(data: object, id: string): Observable<IResponse> {
    return this.api.putRequest('admin', 'client_type/' + id, data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateClient(data: object, id: string): Observable<IResponse> {
    return this.api.putRequest('admin', 'client/' + id, data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  toggleClient(data: object): Observable<IResponse> {
    return this.api.postRequest('admin', 'client/toggle_status', data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteClientType(id: string): Observable<IResponse> {
    return this.api.deleteRequest('admin', 'client_type/' + id).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteClient(id: string): Observable<IResponse> {
    return this.api.deleteRequest('admin', 'client/' + id).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateProfile(data: object): Observable<IResponse> {
    return this.api.putRequest('client', 'update_info', data).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  showClient(clientId): Observable<IResponse> {
    return this.api.getRequest('admin', 'client/' + clientId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getStates(): Observable<IResponse> {
    return this.api.getRequest('states', null).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getLGAS(): Observable<IResponse> {
    return this.api.getRequest('lgas', null).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  showDashboard(): Observable<IResponse> {
    return this.api.getRequest('admin', 'dashboard').pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getDocumentsUploaded(clientId): Observable<IResponse> {
    return this.api.getRequest('client', 'document/list/' + clientId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getDirectorsData(clientId): Observable<IResponse> {
    return this.api.getRequest('client', 'director/list/' + clientId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getAuthorizersData(clientId): Observable<IResponse> {
    return this.api.getRequest('client', 'authorizer/list/' + clientId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getShareholders(clientId): Observable<IResponse> {
    return this.api.getRequest('client', 'shareholder/list/' + clientId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  getOtherFiles(clientId): Observable<IResponse> {
    return this.api.getRequest('admin', 'client_folder/documents/' + clientId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  acceptTerms(term): Observable<IResponse> {
    return this.api.postRequest('privacy_policy', null, term).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  createDocuments(document): Observable<IResponse> {
    return this.api.postRequest('client', 'document/upload', document).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  createOtherFile(document): Observable<IResponse> {
    return this.api.postRequest('admin', 'client_folder', document).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  createAuthorizer(authorizer): Observable<IResponse> {
    return this.api.postRequest('client', 'authorizer', authorizer).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  toggleAuthorizer(id, status): Observable<IResponse> {
    return this.api.postRequest('admin/authorizers', 'toggle_status/' + id, status).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  toggleDirector(id, status): Observable<IResponse> {
    return this.api.postRequest('admin/directors', 'toggle_status/' + id, status).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  toggleShareholder(id, status): Observable<IResponse> {
    return this.api.postRequest('admin/shareholders', 'toggle_status/' + id, status).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  toggleDocument(id, status): Observable<IResponse> {
    return this.api.postRequest('admin/documents', 'toggle_status/' + id, status).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  createDirector(director): Observable<IResponse> {
    return this.api.postRequest('client', 'director', director).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  createShareholder(shareholder): Observable<IResponse> {
    return this.api.postRequest('client', 'shareholder', shareholder).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateDirector(director, directorId): Observable<IResponse> {
    return this.api.postRequest('client', 'director/' + directorId, director).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateAuthorizer(authorizer, authorizerId): Observable<IResponse> {
    return this.api.postRequest('client', 'authorizer/' + authorizerId, authorizer).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateShareholder(shareholder, shareholderId): Observable<IResponse> {
    return this.api.postRequest('client', 'shareholder/' + shareholderId, shareholder).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  updateOtherFile(file, fileId): Observable<IResponse> {
    return this.api.postRequest('admin', 'client_folder/documents/update/' + fileId, file).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteDocument(documentId): Observable<IResponse> {
    return this.api.deleteRequest('client', 'document/' + documentId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteDirector(directorId): Observable<IResponse> {
    return this.api.deleteRequest('client', 'director/' + directorId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteAuthorizer(authorizerId): Observable<IResponse> {
    return this.api.deleteRequest('client', 'authorizer/' + authorizerId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteShareholder(shareholderId): Observable<IResponse> {
    return this.api.deleteRequest('client', 'shareholder/' + shareholderId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
  deleteOtherFile(fileId): Observable<IResponse> {
    return this.api.deleteRequest('admin', 'client_folder/documents/delete/' + fileId).pipe(
      map((res: IResponse) => {
        return res;
      })
    );
  }
 }
