<app-l-header></app-l-header>
<!-- End Navbar -->
<div class="wrapper wrapper-full-page">
  <div class="page-header header-filter" filter-color="black" style="background-image: url('../../assets/images/sleeksolutions.jpg'); background-size: cover; background-position: top center;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-info card-header-icon">
              <div class="card-icon">
                <i class="material-icons"></i>
              </div>
              <!--<h4 class="card-title">Please read the privacy and policy to continue</h4>-->
            </div>
            <div class="card-body px-lg-5" style="z-index: 99999999999999999999999">
              <div class="">
                <h2>Consent Statement</h2>

                <p>We require your consent to collect and lawfully process your information which is required for onboarding to our service.</p>

                <p>
                  By clicking on "I agree", you are granting us your consent and the entity on whose behalf you are providing the information
                  the consent to lawfully process the information provided for the purpose of KYC and Due Diligence.
                </p>



               <p>Please click here to view the terms of our <a href="javascript:void(0)" (click)="viewPrivacy()">Privacy policy</a></p>



              </div>

            </div>
            <div class="card-footer pb-5 px-lg-5">
              <div>
               <button class="btn btn-md btn-danger" (click)="rejectAction()"><i class="fa fa-remove"></i>&nbsp;I do not agree</button>
              </div>
              <div>
                <button class="btn btn-md btn-success" (click)="acceptAction()" *ngIf="!loading"><i class="fa fa-check"></i>&nbsp;I Agree</button>
                <button class="btn btn-md btn-success" *ngIf="loading"><i class="fa fa-spin fa-spinner"></i>&nbsp;Loading...</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <app-l-footer></app-l-footer>
    </footer>
  </div>
</div>


<div class="modal fade" id="privacyPolicy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notice modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel">PRIVACY POLICY</h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="modal-body">

        <!-- <div class="instruction font-weight-bold">
          <div class="row"><div class="col-12">ABOUT US	<span class="float-right">1</span>
          </div></div>
          <div class="row"><div class="col-12">INFORMATION COLLECTED	<span class="float-right">1</span></div></div>
          <div class="row"><div class="col-12">INFORMATION USE	<span class="float-right">2</span></div></div>
          <div class="row"><div class="col-12">DISCLOSURE OF INFORMATION	<span class="float-right">3</span></div></div>
          <div class="row"><div class="col-12">NON-PERSONAL INFORMATION.	<span class="float-right">3</span></div></div>
          <div class="row"><div class="col-12">SECURITY.	<span class="float-right">5</span></div></div>
          <div class="row"><div class="col-12">YOUR RIGHTS.	<span class="float-right">5</span></div></div>
          <div class="row"><div class="col-12">UPDATES TO THIS PRIVACY POLICY.	<span class="float-right">6</span></div></div>

        </div> -->
        <div class="instruction">
          <h3>ABOUT US</h3>
          <p>Upperlink Limited is committed to the privacy of her clients and customers.
            This privacy policy demonstrates our commitment by explaining in clear terms the purposes for which we collect and use your data.
          </p><h3>INFORMATION COLLECTED</h3>
          <p>We collect and use your personal data for the sole purpose of providing our products and services. We collect your personally identifiable data such as:</p>
          ●	Name<br>
          ●	Address<br>
          ●	Telephone number<br>
          ●	Email address<br>
          ●	Date of Birth<br>
          ●	Official government issued-ID<br>
          <h3>INFORMATION USE</h3>
          <p>Our collection of Personal Information is necessary to comply with contractual obligations we have with the  Central Bank of Nigeria (“CBN”) for know your customer and due diligence purposes.
          </p>
          <h3>DISCLOSURE OF INFORMATION</h3>
          <p>Permitted Disclosures - We disclose your Personal Information, when considered necessary or appropriate under applicable law; to comply with legal process; to respond to requests from public and government authorities and to meet national security or law enforcement requirements;  to enforce our terms and conditions; to protect our operations or those of any of our affiliates; to protect our rights, privacy, safety or property, and/or that of our affiliates, you or others; and  to allow us to pursue available remedies or limit the damages that we may sustain.
          </p><h3>NON-PERSONAL INFORMATION.</h3>
          <p>"Non-Personal Information" we may collect is any information that does not reveal your specific identity, such as:
          </p>
          ●	Browser information<br>
          ●	Information collected through cookies, pixel tags and other technologies such as scripts<br>
          ●	Demographic information and other information provided by you<br>
          ●	Aggregated information<br>
          <p>How We Collect Non-Personal Information. We and our third party service providers may collect Non-Personal Information from you, including your computer type, screen resolution, OS version, and Internet browser. We also aggregate Personal Information in a manner such that the end-product does not personally identify you or any other user of the Site, for example, by using Personal Information to calculate the percentage of our users who reside in a particular country.
          </p>
          <p>How We Use and Disclose Non-Personal Information. Because Non-Personal Information does not personally identify you, we may use and disclose Non-Personal Information for any purpose. In some instances, we may combine Non-Personal Information with Personal Information (such as combining your name with your geographical location). If we combine any Non-Personal Information with Personal Information, the combined information will be treated by us as Personal Information as long as it is combined.
          </p>
          <h3>SECURITY.</h3>
          <p>We take our security responsibilities seriously, employing appropriate physical, organisational and technical measures to protect Personal Information under our control. Unfortunately, no data transmission over the Internet or data storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us by sending a mail to support@upperlink.ng
          </p>
          <h3>YOUR RIGHTS.
          </h3>
          <p>We give you choices regarding our use and disclosure of your Personal Information for marketing purposes. You may opt-out from:
          </p>
          <p>●	Receiving electronic communications from us and our affiliates: If you no longer want to receive marketing-related e-mails from us and our affiliates on a going-forward basis, you may opt-out of receiving these marketing-related e-mails by following the instructions to unsubscribe contained within the e-mail.
</p>
          <p>We will seek to comply with your request(s) as soon as reasonably practicable. Please note that if you do opt-out of receiving marketing-related messages from us, we may still send you important administrative messages, and you cannot opt-out from receiving administrative messages.
          </p>
          <h3>ACCESS REQUESTS.
          </h3>
          <p>You have the right to request a copy of the Personal Information held by us about you. You also have the right to access your Personal Information, the right to change any inaccuracies in the details we hold about you, the right to object to the use of your Personal Information and the right to block any specific uses of your Personal Information, by means of a request by emailing us at abuse@upperlnk.ng or by contacting us through our Contact Us web form and by providing the name of the service for which information was provided, your full name, mailing address, phone number and email address so that we may identify you accurately. We will respond to your request within a reasonable timeframe.
          </p>
          <p>For your protection, we will only implement such requests with respect to the Personal Information associated with the particular e-mail address that you use to send us your request, and we may need to verify your identity before implementing your request.
          </p>
          <p>In your request, please make your request clear and we will try to comply with your request as soon as reasonably practicable.
          </p>
          <h3>RETENTION OF PERSONAL INFORMATION.
          </h3>
          <p>We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will retain your personal information for no more than seven years following the date on which you terminate your use of the Services unless we are otherwise required by law or regulation to retain your personal information for longer.
          </p>
          <h3>UPDATES TO THIS PRIVACY POLICY.
          </h3>
          <p>
            We reserve the right to modify this Privacy Policy from time to time without notice to you. Any such changes or modification shall be effective immediately upon sending you notice of such change by email at the email address listed in your Account or when such changes have been posted to this website ("Site") and you next access the Site. Your use of the Site following these changes means that you accept the revised Privacy Policy.
</p>
        </div>
       </div>
      <!-- <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-danger btn-round" data-dismiss="modal" (click)="rejectAction()">I disagree</button>&nbsp;
        <button type="button" class="btn btn-success btn-round" data-dismiss="modal" (click)="acceptAction()">I Agree</button>

      </div> -->
    </div>
  </div>
</div>
