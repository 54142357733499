<div class="wrapper">

<app-sidebar></app-sidebar>

<div class="main-panel">
  <app-header class="mb-5"></app-header>

<div class=""> <div class="mt-small" style="" > <router-outlet class=""></router-outlet></div></div>


<app-footer></app-footer>

</div>


</div>
