import { Component, OnInit } from '@angular/core';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UtilService} from '../../services/utilService/util.service';
import {IResponse} from '../../interfaces/iresponse';
import {AuthService} from '../../services/authService/auth.service';
import { environment as env } from '../../../environments/environment';

declare const $: any;

@Component({
  selector: 'app-policy-privacy',
  templateUrl: './policy-privacy.component.html',
  styleUrls: ['./policy-privacy.component.css']
})
export class PolicyPrivacyComponent implements OnInit {
  public loading = false;
  private currentUser = null;

  constructor(private userService: UserService, private navigatorService: NavigatorService,
              private authService: AuthService,
              private bootstrapService: BootstrapNotifyService, private utilService: UtilService) { }

  ngOnInit(): void {
    this.utilService.setFullPageBackgroundImage();
    if (!this.authService.checkLogin()) {
      this.navigatorService.navigateUrl('/');
    }

    this.currentUser = this.authService.getUserDetails();
    // console.log('User ', this.currentUser);
  }
  public rejectAction() {
    this.userService.acceptTerms({privacy_policy_status: 'rejected', user_id: this.currentUser.id}).subscribe((res: IResponse) => {
      if (this.authService.logOut()) {
        this.navigatorService.navigateUrl('/');
      }
    }, err => {
      if (this.authService.logOut()) {
        this.navigatorService.navigateUrl('/');
      }
    });

  }
  public acceptAction() {
  this.loading = true;
  this.userService.acceptTerms({privacy_policy_status: 'accepted', user_id: this.currentUser.id}).subscribe((res: IResponse) => {
    this.loading = false;
    sessionStorage.setItem(env.USERTOKEN, JSON.stringify(res.data));
    this.bootstrapService.success(res.message || 'Privacy policy accepted successfully!');
    this.navigatorService.navigateUrl('/client/manage-documents');
  }, err => {
    this.loading = false;
    this.bootstrapService.error(err.err.message || 'Unable to accept privacy policy!');
  });
  }
  viewPrivacy() {
    this.utilService.openModal('privacyPolicy');
  }
}
