import {
  ChangeDetectorRef, Component, Input,
  OnInit
} from '@angular/core';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {AuthService} from '../../services/authService/auth.service';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {EventsService} from '../../services/eventServices/event.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css']
})
export class PersonalDataComponent implements OnInit {

  @Input() mode = null;
  public profile = {
  client_type_id: null,
  client_type: null,
  first_name: null,
  last_name: null,
  email: null,
  tax_id : null,
  registration_number : null,
  lga_id : 1,
  state_id: 1,
  registered_address : null,
  industry : null,
  state_name: null,
  lga_name: null,
  source_of_fund : null,
  transaction_purpose : null,
  risk_rating : null,
  estimated_income_value : null,
  estimated_monthly_transaction : null,
  legal_form: null,
  nature_of_business: null
};
  states = [];
  lgas = [];
  private currentUser;
  public loading = false;
  private clientId;
  public riskRatings = [
    {name: 'High', id: 'high'},
    {name: 'Medium', id: 'medium'},
    {name: 'Low', id: 'low'},
  ];
  legalForms = [
    {id: 'public_limited', name: 'Public Limited'},
    {id: 'limited_liability_company', name: 'Limited Liability Company'},
    {id: 'joint_stock_company', name: 'Joint Stock Company'},
    {id: 'partnership', name: 'Partnership'},
    {id: 'private_entity', name: 'Private Entity'},
    {id: 'government_entity', name: 'Government Entity'}
  ];
  // private cdr: ChangeDetectorRef,

constructor(private userService: UserService, private authService: AuthService,
            private route: ActivatedRoute,
            private bootstrapNotifyService: BootstrapNotifyService, private eventService: EventsService) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    if (!this.mode) {
    this.currentUser = this.authService.getUserDetails();
    this.profile = this.currentUser.client;
    this.profile.state_id = this.currentUser?.client?.lga?.state_id || null;
  } else {
    // handle new api calls
    this.getClient();
  }
    this.getStates();
    this.getLGA();
  }/*
  ngAfterContentChecked() {
    this.cdr.detectChanges();
    // call or add here your code
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
    // call or add here your code
  }*/
  private getClient() {
    this.userService.showClient(this.clientId).subscribe((res) => {
      console.log('Res', res);
      this.currentUser = res.data;
      this.profile = this.currentUser;
      this.profile.state_id = this.currentUser.lga?.state_id;
      this.profile.state_name = this.currentUser?.lga?.state?.name || null;
      this.profile.lga_name = this.currentUser?.lga?.name || null;
      console.log('State ', this.profile);
    }, error => {
      console.log('Error ', error);
    });
  }
  public updateProfile() {
  this.loading = true;
  this.profile.last_name = this.profile.first_name;
  this.userService.updateProfile(this.profile).subscribe((res) => {
    this.loading = false;
    console.log('Res', res);
    this.eventService.broadcast('PROFILE_UPDATED', res.data.status, this.profile);
    this.bootstrapNotifyService.success('Profile updated!');
  }, error => {
    console.log('Error ', error);
    this.loading = false;
    this.bootstrapNotifyService.error(error.error.message || 'Unable to update profile!');
  });
  }
  public getStates() {
    this.userService.getStates().subscribe((res) => {
      console.log('Res', res);
      this.states = res.data;
    }, error => {
      console.log('Error ', error);
    });
  }
  public getLGAS(e) {
  this.profile.lga_id = null;
  this.lgas = e.lgas;
  }
  public getLGA() {
    this.userService.getLGAS().subscribe((res) => {
        console.log('Res', res);
        this.lgas = res.data;
      }, error => {
        console.log('Error ', error);
      });
  }

}
