<div class="content">
  <div class="container-fluid mt-sm-5 pt-sm-5">

    <div class="row">
      <div class="col-md-6 col-sm-12 offset-md-3 mt-5">
<app-user-change-password></app-user-change-password>
      </div>
    </div>



  </div>
</div>


