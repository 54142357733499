<!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " id="navigation-example">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-just-icon btn-white btn-fab btn-round" (click)="toggleMiniSidebar()">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      <a class="navbar-brand d-md-block" href="javascript:void(0)" *ngIf="breadcrumb">{{breadcrumb}}</a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle" data-target="#navigation-example">
      <span class="sr-only">Toggle</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>


    <ul class="navbar-nav">

       <li class="nav-item d-none d-sm-none d-lg-block d-md-block pr-5" *ngIf="userRole.toLowerCase() === 'admin'">
         <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
           <i class="material-icons">person</i>
         </a>
         <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
           <a class="dropdown-item" href="javascript:void(0)" (click)="logOutUser()">Change Password</a>
         </div>
       </li>
      <li class="nav-item">
        <a class="logout text-rose" href="javascript:void(0)" (click)="logOutUser()"><i class="fa fa-power-off">&nbsp; Sign Out</i></a>
      </li>

    </ul>


  </div>
</nav>
<!-- End Navbar -->
