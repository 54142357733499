

<div class="card">
  <div class="card-header card-header-icon card-header-rose">
    <div class="card-icon">
      <i class="material-icons">perm_identity</i>
    </div>
    <h4 class="card-title">{{mode === 'readonly' ? 'View' : 'Edit'}} Profile -
      <small class="category">Complete your profile</small>
    </h4>
  </div>
  <div class="card-body">
    <form novalidate (ngSubmit)="updateProfile()">
      <!-- <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">First Name</label>
            <input type="text" class="form-control" 
                   >
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Last Name</label>
            <input type="text" class="form-control" 
                   >
          </div>
        </div>
      </div> -->
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Valid Organization email</label>
            <input [disabled]="mode === 'readonly'" type="email" class="form-control"
                   [(ngModel)]="profile.email"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Client Type</label>
            <input type="text" class="form-control"
                   [(ngModel)]="profile.client_type.name"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Full Legal Business Name</label>
            <div class="text-rose" style="margin-top: -15px;font-size: 10px">As included in business reg. document</div>
            <input type="text" class="form-control" [(ngModel)]="profile.first_name"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <!--<div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Last Name</label>
            <input type="text" class="form-control" [(ngModel)]="profile.last_name"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>-->
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Registered Address</label>
            <input [disabled]="mode === 'readonly'" type="text" class="form-control" [(ngModel)]="profile.registered_address"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Registration Number</label>
            <input [disabled]="mode === 'readonly'" type="text" class="form-control" [(ngModel)]="profile.registration_number"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Industry</label>
            <input [disabled]="mode === 'readonly'" type="text" class="form-control" [(ngModel)]="profile.industry"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Source of fund</label>
            <input [disabled]="mode === 'readonly'" type="text" class="form-control" [(ngModel)]="profile.source_of_fund"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>

      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="form-group bmd-form-group mt-3">
            <label class="bold-label">Tax Id</label>
            <input [disabled]="mode === 'readonly'" type="text" class="form-control" [(ngModel)]="profile.tax_id"
                   [ngModelOptions]="{standalone: true}">
          </div>
        </div>
       <!-- <div class="col-md-4">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Country</label>
            <input type="text" class="form-control" value="Nigeria" disabled>
          </div>
        </div>-->
        <div class="col-md-4">

          <div class="form-group bmd-form-group mb-3 mt-1">
            <!--<label class="">Client Type *</label>-->
            <!--[searchable]="false"-->
            <ng-select [items]="states" class="ng-select-form" id="stateId" #state
                       [notFoundText]="'No matching State'"
                       [disabled]="mode === 'readonly'"
                       placeholder="Select State"
                       bindLabel="name" (change)="getLGAS($event)"
                       bindValue="id"
                       [(ngModel)]="profile.state_id" [ngModelOptions]="{standalone: true}">
              <!--<ng-template ng-header-tmp>
                <input style="width: 100%; line-height: 24px" type="text" placeholder="Search..." (input)="state.filter($event.target.value)" />
              </ng-template>-->
            </ng-select>
          </div>

        </div>

        <div class="col-md-4">

          <div class="form-group bmd-form-group mb-3 mt-1">
            <!--<label class="">Client Type *</label>-->
            <ng-select 
                      [items]="lgas" class="ng-select-form" id="lgaId" #lga [searchable]="false"
                       [notFoundText]="'No matching LGA'" [disabled]="!profile.state_id || mode === 'readonly'"
                       placeholder="Select Local government area"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="profile.lga_id" [ngModelOptions]="{standalone: true}">
              <ng-template ng-header-tmp>
                <input  style="width: 100%; line-height: 24px" type="text" placeholder="Search..." (input)="lga.filter($event.target.value)"/>
              </ng-template>
            </ng-select>
          </div>

        </div>

      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Nature of business</label>
            <textarea [disabled]="mode === 'readonly'" class="form-control" [(ngModel)]="profile.nature_of_business"
                      [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <!--<label class="bold-label pb-4">Nature of business</label><br><br>-->
            <!--<select class="form-control" [(ngModel)]="profile.nature_of_business"
                      [ngModelOptions]="{standalone: true}">
              <option disabled>Select Nature of Business</option>
              <option *ngFor="let nature of natureOfBusiness" [value]="nature" class="text-capitalize">{{nature.split('_').join(' ')}}</option>
            </select>-->


            <ng-select [items]="legalForms" class="ng-select-form" id="legal_form" #legal_form [searchable]="false"
                       [notFoundText]="'No matching legal form'"
                       placeholder="Select legal form"
                       [disabled]="mode === 'readonly'"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="profile.legal_form" [ngModelOptions]="{standalone: true}">
              <!--<ng-template ng-header-tmp>
                <input style="width: 100%; line-height: 24px" type="text" placeholder="Search..." (input)="nature_of_business.filter($event.target.value)"/>
              </ng-template>-->
            </ng-select>

          </div>
        </div>


      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Estimated monthly transaction</label>


            <div class="row">
              <label class="col-sm-2 col-form-label" style="text-align: center">NGN</label>
              <div class="col-sm-10">
                <input type="text" [disabled]="mode === 'readonly'" class="form-control" [(ngModel)]="profile.estimated_monthly_transaction"
                       [ngModelOptions]="{standalone: true}">
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group bmd-form-group">
            <label class="bold-label">Estimated income value</label>
            <div class="row">
              <label class="col-sm-2 col-form-label" style="text-align: center">NGN</label>
              <div class="col-sm-10">
                <input [disabled]="mode === 'readonly'" type="text" class="form-control" [(ngModel)]="profile.estimated_income_value"
                       [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-6">
          <div class="form-group">
            <label>Transaction purpose</label>
            <div class="form-group bmd-form-group">
              <textarea [disabled]="mode === 'readonly'" class="form-control" [(ngModel)]="profile.transaction_purpose"
                        [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>
        </div>
        <div *ngIf="mode === 'readonly'" class="col-md-6">
          <div class="form-group bmd-form-group mb-3">
            <!--<label class="">Client Type *</label>-->
            <ng-select [items]="riskRatings" class="ng-select-form" id="riskRating" [searchable]="false"
                       placeholder="Select risk rating"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="profile.risk_rating" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
        </div>
      </div>
      <ng-container ><button type="submit" *ngIf="!loading" class="btn btn-rose pull-right">Update Profile</button>
      <button type="button" *ngIf="loading" class="btn btn-rose pull-right"><i class="fa fa-spinner fa-spin"></i>&nbsp;Please wait!</button>
      <div class="clearfix"></div></ng-container>
    </form>
  </div>


  <div class="card-body" *ngIf="false">
    <form novalidate (ngSubmit)="updateProfile()">
      
    </form>


    <div class="row mt-3">
      <div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Email</label>
          <br>

          <input type="email" class="form-control" readonly
                 [value]="profile?.email">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Client Type</label>
          <br>
          <input type="text" class="form-control" disabled
                 [value]="profile?.client_type?.name">
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="form-group">
          <label class="bold-label">Full Legal Business Name</label>
          <br>

          <input type="text" class="form-control" [value]="profile?.first_name ? profile?.first_name : ''">
        </div>
      </div>
      <!--<div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Last Name</label>
          <br>

          <input type="text" class="form-control" [value]="profile?.last_name" readonly>
        </div>
      </div>-->
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="bold-label">Registered Address</label>
          <br>
          <input type="text" class="form-control" [value]="profile?.registered_address" readonly>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="form-group">
          <label class="bold-label">Registration Number</label>
          <br>

          <input type="text" class="form-control" [value]="profile?.registration_number" readonly>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="bold-label">Industry</label>
          <br>

          <input type="text" class="form-control" [value]="profile?.industry" readonly>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="bold-label">Source of fund</label>
          <br>

          <input type="text" class="form-control" [value]="profile?.source_of_fund" readonly>
        </div>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <div class="form-group">
          <label class="bold-label">Tax Id</label>
          <br>

          <input type="text" class="form-control" [value]="profile?.tax_id" readonly>
        </div>
      </div><!--
      <div class="col-md-4">
        <div class="form-group">
          <label class="bold-label">Country</label>
          <br>

          <input type="text" class="form-control" value="Nigeria" disabled readonly>
        </div>
      </div>-->


      <div class="col-md-4">

        <div class="form-group">
          <label class="bold-label">State</label>
          <!--<ng-select [items]="states" class="ng-select-form" id="stateView" #state [searchable]="false"
                     [notFoundText]="'No matching State'"
                     placeholder="Select State"
                     bindLabel="name" readonly
                     bindValue="id"
                     disabled="true"
                     [(ngModel)]="profile.state_id" [ngModelOptions]="{standalone: true}">
            <ng-template ng-header-tmp>
              <input style="width: 100%; line-height: 24px" type="text" placeholder="Search..." (input)="state.filter($event.target.value)"/>
            </ng-template>
          </ng-select>-->
          <input type="text" class="form-control" [value]="profile?.state_name && profile.state_name !=='undefined' ? profile.state_name : ''" readonly>

        </div>

      </div>


      <div class="col-md-4">
        <div class="form-group">
          <label class="bold-label">Local Government Area</label>
          <!--<br>
          <ng-select [items]="lgas" class="ng-select-form" id="lgaView" #lga [searchable]="false"
                     [notFoundText]="'No matching LGA'"
                     bindLabel="name"
                     bindValue="id" readonly
                     disabled="true"
                     [(ngModel)]="profile.lga_id" [ngModelOptions]="{standalone: true}">
            <ng-template ng-header-tmp>
              <input style="width: 100%; line-height: 24px" type="text" placeholder="Search..." (input)="lga.filter($event.target.value)"/>
            </ng-template>
          </ng-select>-->
          <input type="text" class="form-control" [value]="profile?.lga_name && profile.lga_name !=='undefined' ? profile.lga_name : ''" readonly>
        </div>
      </div>

    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Nature of business</label>
          <textarea class="form-control" [value]="profile?.nature_of_business" readonly></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Legal Form</label>
          <input type="text" class="form-control" [value]="profile?.legal_form?.split('_')?.join(' ')?.toUpperCase()" readonly>
        </div>
      </div>


    </div>
    <div class="row mt-3">
      <div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Estimated monthly transaction</label>
          <br>


          <div class="row">
            <!--<label class="col-sm-2 col-form-label" style="text-align: center">NGN</label>-->
            <div class="col-sm-10">
              <input type="text" class="form-control" [value]="profile?.estimated_monthly_transaction ? 'NGN ' + profile?.estimated_monthly_transaction : ''  " readonly>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="bold-label">Estimated income value</label>
          <br>

          <div class="row">
            <!--<label class="col-sm-2 col-form-label" style="text-align: center">NGN</label>-->
            <div class="col-sm-10">
              <input type="text" class="form-control" [value]="profile?.estimated_income_value ? 'NGN ' + profile?.estimated_income_value : ''" readonly>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="form-group">
          <label>Transaction purpose</label>
          <div class="form-group">
              <textarea class="form-control" [value]="profile?.transaction_purpose" readonly></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
