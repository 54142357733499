<footer class="footer">
  <div class="container">
    <div class="copyright float-left">
      &copy;{{year}} KYC Portal.
    </div>
    <div class="copyright float-right">
      All right reserved.Powered by  <a href="https://www.upperlink.ng" target="_blank">Upperlink Limited</a>
    </div>
  </div>
</footer>
