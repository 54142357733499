

<div class="" id="displayOtherFiles">
  <div class="row mb-3 pb-3" *ngIf="otherFiles.length">
    <div class="col-12">
      <a href="javascript:void(0)" class="btn btn-rose btn-round float-right" *ngIf="!loading" (click)="allowUpload()">
        <i class="material-icons">add</i>&nbsp;
        Create File<div class="ripple-container"></div></a>
    </div>
  </div>

  <div class="row" *ngIf="!otherFiles.length">
    <div class="col-md-12">
      <div class="card" style="min-height: 240px">
        <div class="card-header text-center" *ngIf="!loading">
          <h4 class="card-title mt-5 pt-5">No File created yet</h4>
        </div>
        <div class="card-body text-center">
          <div *ngIf="loading" class="mt-5 pt-5">
            <i class="fa fa-spin fa-spinner fa-2x"></i>&nbsp;Loading...
          </div>
          <a href="javascript:void(0)" class="btn btn-rose btn-round" *ngIf="!loading" (click)="allowUpload()">
            <i class="material-icons">add</i>&nbsp;
            Create File<div class="ripple-container"></div></a>
        </div>
      </div>
    </div>
  </div>


  <div class="row" *ngIf="otherFiles.length && !loading">
    <div class="col-md-6" *ngFor="let otherFile of otherFiles">
      <div class="card card-product">
        <div class="card-header card-header-image" data-header-animation="true">
          <a href="javascript:void(0)" (click)="allowUpload()">
            <img class="img" [src]="otherFile?.file"  onerror="this.src='../assets/images/file.jpg'" style="min-height: 300px;max-height: 300px">
          </a>
        </div>
        <div class="card-body">
          <div class="card-actions text-center" >
            <button type="button" class="btn btn-success btn-link" (click)="editOtherFile(otherFile)" >
              <i class="material-icons">edit</i> Edit
            </button>
            <button type="button" (click)="deleteOtherFile(otherFile)" class="btn btn-danger btn-link">
              <i class="material-icons">close</i> Delete
            </button>
            <button type="button" class="btn btn-info btn-link" (click)="printFile(otherFile?.file, 'File')">Print</button>
          </div>
<!--

          <div  class="card-actions text-center" >

            <button type="button" class="btn btn-success btn-link" (click)="verify(otherFile, 'verify')" *ngIf="otherFile.identification_status === 'NOT_VERIFIED'">Verify</button>
            <button type="button" class="btn btn-warning btn-link" (click)="verify(otherFile, 'unverify')" *ngIf="otherFile.identification_status === 'VERIFIED'">Unverify</button>
            <button type="button" class="btn btn-info btn-link" (click)="printFile(otherFile?.identification_image, 'File')">Print</button>
          </div>
-->

          <h4 class="card-title text-left">
            <a href="javascript:void(0)">Name: {{otherFile?.name}}</a>
          </h4>
          <div class="card-description text-left">
            Comment: {{otherFile?.comment}}
          </div>
        </div>
        <div class="card-footer">
          <div class="stats">
            <!--<small>{{otherFile?.identification_type}}</small>-->
          </div>
          <div class="stats">
            <p class="card-category">Date Created: {{ otherFile?.created_at | date:'medium'}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showUpload">
  <div class="card">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">assignment</i>
      </div>
      <h4 class="card-title">File
      </h4>
    </div>



    <div class="card-body">
      <form novalidate (ngSubmit)="createOtherFile()">

        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">File Name</label>
              <input type="text" class="form-control" [(ngModel)]="otherFile.name" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">Comment</label>
              <textarea class="form-control" [(ngModel)]="otherFile.comment"
                        [ngModelOptions]="{standalone: true}"></textarea>
            </div>
          </div>
        </div>


        <div class="row mt-3">
          <!--<div class="col-md-6 col-sm-12">
            <div class="form-group bmd-form-group">
              <label class="bold-label">Identification type</label><br>
              <select class="selectpicker" data-style="btn btn-rose" title="Select identification type"  [(ngModel)]="otherFile.identification_type"
                      [ngModelOptions]="{standalone: true}">
                <option disabled selected="">Select Identification type</option>
                <option *ngFor="let type of types" [value]="type.id">{{type.value}}</option>
              </select>
            </div>
            <div class="font-weight-bold text-rose" style="font-size: 9px;">Upload recent means of identification</div>

          </div>-->
          <div class="col-md-6 col-sm-12">
            <div class="mt-2">
              <label class="bold-label">Select File</label><br>
              <!--<input type="file"  (change)="getTargetFile($event)">-->
              <input type="file" accept="application/pdf,image/png,image/jpg,image/jpeg" (change)="getTargetFile($event)">
              <div style="font-size: 10px" class="text-rose">Accepted format: PNG, JPG, JPEG, PDF</div>
              <!--<a [href]="otherFile?.file" *ngIf="otherFile.file && !upload" target="_blank" style="font-size: 9px">{{otherFile?.file}}</a>-->
            </div>
          </div>

        </div>
        <button type="button" *ngIf="!saving" class="btn btn-default pull-right" (click)="showUploads()">Cancel</button>&nbsp;
        <button type="submit" *ngIf="!saving" class="btn btn-rose pull-right">{{ update ? "Update File" : "Create File" }}</button>
        <button type="button" *ngIf="saving" class="btn btn-rose pull-right"><i class="fa fa-spinner fa-spin"></i>&nbsp;Please wait!</button>
        <div class="clearfix"></div>
      </form>
    </div>

  </div>

</ng-container>
