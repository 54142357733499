<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white" id="navigation-example">
  <div class="container">
    <div class="navbar-wrapper">
      <a class="navbar-brand font-weight-bold" href="javascript:void(0)" [routerLink]="['/']"><img src="assets/images/upperlink-logo.png" width="150"></a>
    </div>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">

        <li class="nav-item ">
          <a href="javascript:void(0)" [routerLink]="['/']" class="nav-link">
            <i class="material-icons">fingerprint</i> KYC PORTAL
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
