import {Component, Input, OnInit} from '@angular/core';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/api-handlers/userService/user.service';
import {AuthService} from '../../services/authService/auth.service';
import {UtilService} from "../../services/utilService/util.service";
import {ActivatedRoute} from "@angular/router";
declare const $: any;

@Component({
  selector: 'app-manage-documents',
  templateUrl: './manage-documents.component.html',
  styleUrls: ['./manage-documents.component.css']
})
export class ManageDocumentsComponent implements OnInit {
  @Input() mode = null;

  public documents = [];
  public types = [];
  public newDoc;
  public newDocuments = [];
  public loading = false;
  public saving = false;
  public showUpload = false;
  private currentUser;
  private clientId;
  constructor(private userService: UserService,
              private authService: AuthService,
              private utilService: UtilService,
              private route: ActivatedRoute,
              private bootstrapNotifyService: BootstrapNotifyService) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    if (!this.mode) {
      this.currentUser = this.authService.getUserDetails();
      this.getDocuments(this.currentUser.client.id);
      console.log('currentUser', this.currentUser);
      if (this.currentUser.client && this.currentUser.client.client_type.name === 'Corporate') {
        this.types = [
          {id: 'certificate_of_incorporation', value: 'Certificate of incorporation'},
          {id: 'memorandum_of_association', value: 'Memorandum of association'},
          {id: 'utility_bill', value: 'Utility bill'},
          {id: 'board_resolution', value: 'Board resolution'},
          {id: 'cac_2', value: 'CAC 2'},
          {id: 'cac_7', value: 'CAC 7'},
        ];
      } else {
        this.types = [
          {id: 'authorization_letter', value: 'Authorization letter'},
          {id: 'gazette', value: 'Gazette'}
        ];
      }
    } else {
      // handle new api calls
      this.getDocuments(this.clientId);

    }
  }


  public verify(doc, type) {
    this.userService.toggleDocument(doc.id, {status: type === 'verify' ? 1 : 0 }).subscribe((res) => {
      console.log('Res', res);
      this.getDocuments(this.clientId);
      this.bootstrapNotifyService.success(res.message);
    }, error => {
      // console.log('ERROR', error);
      this.bootstrapNotifyService.error(error.error.message ||  'Unable to ' + type + ' document');
    });
  }

  public printFile(url, name) {
    this.utilService.printDocument(url, name, 'image');
  }
  public getDocuments(clientId = this.currentUser.client.id) {
    this.loading = true;
    this.userService.getDocumentsUploaded(clientId).subscribe((res) => {
      this.loading = false;
      console.log('Res', res);
      this.documents = res.data;
    }, error => {
      console.log('Error ', error);
      this.loading = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to list uploaded documents!');
    });
  }
  public addNewDocument(e) {
    this.newDocuments.push({
      id: this.newDoc,
      file: null
    });
    console.log('Documents ', this.newDocuments);
  }
  public getTargetFile(e, document) {

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (() => {
      document.file = reader.result;
    });
    reader.readAsDataURL(file);
  }
  public removeSelectedDocument(index) {
    if (this.saving) return false;
    this.newDocuments.splice(index, 1);
    console.log('Documents ', this.newDocuments);
  }
  public allowUpload() {
    $('#displayDocuments').addClass('d-none');
    this.showUpload = true;
    setTimeout(() => {
      $('.selectpicker').selectpicker();
    }, 20);
  }
  public showUploads() {
    $('#displayDocuments').removeClass('d-none');
    this.showUpload = false;
  }
  public deleteUploadedDoc(doc) {
    this.utilService.confirmAction(() => {
      this.userService.deleteDocument(doc.id).subscribe((res) => {
        console.log('Res', res);
        this.bootstrapNotifyService.success('Document deleted successfully');
        this.getDocuments();
      }, error => {
        this.bootstrapNotifyService.error(error.error.message || 'Unable to delete document');
      });
    });
  }
  public saveDocuments() {
    const document = {};
    console.log('Type ', this.types);
    this.types.forEach((doc) => {
      if (doc && doc.file) {
        document[doc.id] = doc.file.split(';base64,')[1];
        document[doc.id + '_type'] = doc.file.split(';base64,')[0].split('/')[1];
      }
    });
    this.saving = true;
    this.userService.createDocuments(document).subscribe((res) => {
      this.saving = false;
      this.newDocuments = [];
      console.log('Res', res);
      this.bootstrapNotifyService.success('Document uploaded successfully');
      this.newDoc = null;
      this.showUploads();
      this.getDocuments();
    }, error => {
      this.saving = false;
      this.bootstrapNotifyService.error(error.error.message || 'Unable to upload documents');
    });
  }
}
