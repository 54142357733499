import {Component, OnInit, ViewEncapsulation} from '@angular/core';
@Component({
  selector: 'app-admin-routes',
  templateUrl: './admin-routes.component.html',
  styleUrls: ['./admin-routes.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class AdminRoutesComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
