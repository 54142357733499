<app-l-header></app-l-header>
<!-- End Navbar -->
<div class="wrapper wrapper-full-page">
  <div class="page-header login-page header-filter" filter-color="black" style="background-image: url('../../assets/images/bespoke.jpg'); background-size: cover; background-position: top center;">
    <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
    <div class="container">
      <div class="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
        <div class="card card-login card-hidden">
          <div class="card-header card-header-warning text-center">
            <h4 class="card-title">Reset Password</h4>
            <div class="social-line">
              Set new password for your account
            </div>
          </div>
          <form novalidate  #loginForm="ngForm" (ngSubmit)="resetPassword()">
            <div class="card-body">
              <div class="form-group">
                <div class="input-group px-4">
                  <input class="form-control" placeholder="Enter new password" type="password"
                         #password="ngModel" minlength="7" id="password"
                         [(ngModel)]="credentials.password" [ngModelOptions]="{standalone: true}" required>


                </div>
                <div class="text-danger pl-4"  *ngIf="password.errors && (password.dirty || password.touched)" style="margin-top: -5px;">
                  <span class="validation-message" [hidden]="!password.hasError('minlength')">
                 Invalid password</span>
                  <span class="validation-message" [hidden]="!password.hasError('required')">
                 Password is required</span>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group px-4">
                  <input class="form-control" placeholder="Confirm new password" type="password"
                         #reenterPassword="ngModel" minlength="7" id="reenterPassword"
                         [(ngModel)]="credentials.password_confirmation" [ngModelOptions]="{standalone: true}" required>


                </div>
                <div class="text-danger pl-4"  *ngIf="reenterPassword.errors && (reenterPassword.dirty || reenterPassword.touched)" style="margin-top: -5px;">
                  <span class="validation-message" [hidden]="!reenterPassword.hasError('minlength')">
                 Invalid password</span>
                  <span class="validation-message" [hidden]="!reenterPassword.hasError('required')">
                 Password is required</span>
                </div>
                <div class="text-danger pl-4"  *ngIf="reenterPassword.dirty && password.touched" style="margin-top: -5px;">
                  <span class="validation-message" [hidden]="credentials.password === credentials.password_confirmation ">
                 Password must match</span>
                </div>
              </div>


            </div>
            <div class="card-footer justify-content-center">
              <button type="submit" class="btn btn-warning btn-lg" [disabled]="password.errors || reenterPassword.errors || (credentials.password !== credentials.password_confirmation)">
                <i class="fa fa-spinner fa-spin" *ngIf="loaders.loading"></i> &nbsp;Reset Password</button>
            </div>

            <div style="font-size: 13px;">
              <p class="text-left px-2">Already have account?<a href="javascript:void(0)" [routerLink]="['/']" class="text-rose">Login</a></p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <footer class="footer">
      <app-l-footer></app-l-footer>
    </footer>
  </div>
</div>
