import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {EventsService} from '../../../services/eventServices/event.service';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {CacheService} from '../../../services/cacheService/cache.service';
import {GuardService} from '../../../services/gaurdService/guard.service';
import {NotifyComponent} from '../../components/notify/notify.component';
import {CSVService} from '../../../services/csvServices/pdf.service';
import {SidebarComponent} from '../../layout/dashboard/sidebar/sidebar.component';
import {FooterComponent} from '../../layout/dashboard/footer/footer.component';
import {HeaderComponent} from '../../layout/dashboard/header/header.component';
import {NotificationService} from '../../../services/notificationServices/notification.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {UserChangePasswordComponent} from '../../components/user-change-password/user-change-password.component';
import {PersonalDataComponent} from '../../../user/personal-data/personal-data.component';
import {ManageDocumentsComponent} from '../../../user/manage-documents/manage-documents.component';
import {ManageDirectorsComponent} from '../../../user/manage-directors/manage-directors.component';
import {ManageShareholdersComponent} from '../../../user/manage-shareholders/manage-shareholders.component';
import {ManageAuthorizersComponent} from '../../../user/manage-authorizers/manage-authorizers.component';

@NgModule({
  declarations: [
    NotifyComponent,
    SidebarComponent,
    FooterComponent,
    UserChangePasswordComponent,
    HeaderComponent,



    PersonalDataComponent,
    ManageDocumentsComponent,
    ManageDirectorsComponent,
    ManageShareholdersComponent,
    ManageAuthorizersComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    EventsService,
    NavigatorService,
    GuardService,
    CacheService,
    NotificationService,
    CSVService
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NotifyComponent,
    SidebarComponent,
    FooterComponent,
    UserChangePasswordComponent,
    NgSelectModule,
    ReactiveFormsModule,
    HeaderComponent,


    PersonalDataComponent,
    ManageDocumentsComponent,
    ManageDirectorsComponent,
    ManageShareholdersComponent,
    ManageAuthorizersComponent,
    ]
})
export class SharedModule { }
