<div class="wrapper">

  <app-sidebar></app-sidebar>

  <div class="main-panel">
    <app-header class="mb-5"></app-header>

    <div class="" style="min-height: 68vh">
      <div class="" style="margin-top:5%;padding: 0 22px;">
        <router-outlet class=""></router-outlet>
      </div>
    </div>


    <app-footer></app-footer>

   <!-- <footer class="footer">
      <app-footer></app-footer>
    </footer>-->
  </div>


</div>
